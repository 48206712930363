<section class="bmw-slider">
    <carousel>
        <slide class="carousel-inner">
            <div class="slider-zoom">
                <img src="/assets/images/td-Slider1.jpg" class="w-100" alt="BMW slides">
            </div>
        </slide>
        <slide class="carousel-inner">
            <div class="slider-zoom">
                <img src="/assets/images/td-Slider2.jpg" class="w-100" alt="BMW slides">
            </div>
        </slide>
        <slide class="carousel-inner">
            <div class="slider-zoom">
                <img src="/assets/images/td-Slider3.jpg" class="w-100" alt="BMW slides">
            </div>
        </slide>
        <!-- <slide class="carousel-inner">
            <div class="slider-zoom">
                <img src="/assets/images/td-Slider4.jpg" class="w-100" alt="BMW slides">
            </div>
        </slide> -->
    </carousel>
</section>
<section>
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="training-content">
                    <h1>
                        BMW Group Training & Development Center
                    </h1>
                    <p class="mb-4">
                        At BMW Group, just reaching the peak of automotive brilliance isn’t good enough. Not only do we
                        strive to maintain the standards that we set, we seek to better them. And make sure that every
                        individual and business entity associated with us is well equipped to take forward the same
                        beliefs. It is this philosophy that gave birth to the BMW Group Training Centre, a
                        state-of-the-art facility with the most advanced training tools and equipment in the automobile
                        sector in India.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <img src="/assets/images/training-development.jpg" class="img-fluid  pb-lg-0 pb-3">
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="mb-3">
                    <h2 class="sub-title">BMW Group Training Centre</h2><p>, located in Gurugram (Haryana) is Gold IGBC (Indian Green
                        Building Council) LEED (Leadership in Energy and Environmental Design) certified facility that
                        is spread over an area of 2.0 acres with a building area of 60,000 square foot This Centre of
                        excellence been running successfully since 2014 and delivers trainings to Indian as well as
                        oversees associates in the areas of <b>:</b> </p>
                </div>
                <div class="Training-list">
                    <ul>
                        <li><i class="fas fa-caret-right"></i><span> Sales</span></li>
                        <li><i class="fas fa-caret-right"></i><span> Product</span></li>
                        <li><i class="fas fa-caret-right"></i><span> Management</span></li>
                        <li><i class="fas fa-caret-right"></i><span> Aftersales</span></li>
                        <li><i class="fas fa-caret-right"></i><span> Brand</span></li>
                        <li><i class="fas fa-caret-right"></i><span> Technical and Bodyshop</span></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-12">
                <p class="my-3">
                    All associates who join our dealer network are given an opportunity to go through an extensive set
                    of training courses and role-specific Certifications which ensures superior professionalism. These
                    varied learning opportunities not only help our retail employees in improving and developing their
                    skills to offer premium customer experience but also focuses on making them grow professionally in
                    their career.
                </p>
                <br>
            </div>
        </div>
    </div>
</section>