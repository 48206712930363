import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-document',
  templateUrl: './legal-document.component.html',
  styleUrls: ['./legal-document.component.scss']
})
export class LegalDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
