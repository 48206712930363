import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocationDetailsComponent } from './location-details/location-details.component';

@Component({
  selector: 'app-dealer-locations',
  templateUrl: './dealer-locations.component.html',
  styleUrls: ['./dealer-locations.component.scss']
})
export class DealerLocationsComponent implements OnInit {

  dealerRegions = {
    "01_NORTH & EAST": [{
      showroom: "BIRD AUTOMOTIVE",
      location: "Delhi",
      subLocation: "Delhi NCR",
      findUs: {
        bmw: {
          showroom: [
            { address: "MG Road, Gurgaon", detailed_address: "Plot No-4, IDC, Sector 14, MG Road, Gurgaon", location: "https://goo.gl/maps/QqKdr4wUhVE6sa3z8" },
            { address: "Golf Course Road, Gurgaon", detailed_address: "1 Baani, Golf Course Road, Gurgaon", location: "https://goo.gl/maps/aPh7n1fKNRMK3E8j8" }
          ],
          service: [
          ]
        },
        mini: {
          showroom: [
            { address: "Vasant Kunj, Delhi", detailed_address: "Plot 8&9, Pocket 6&7, Sector 6, LSC, Vasant Kunj, Delhi", location: "https://goo.gl/maps/7krKpq5N8MyvZ2bn6" }
          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "DEUTSCHE MOTOREN",
      location: "Delhi",
      subLocation: "Delhi NCR",
      findUs: {
        bmw: {
          showroom: [
            { address: "Mathura Road, Faridabad", detailed_address: "Plot No-1, Sector 27-B, Urban Estate, Mathura Road, Faridabad", location: "https://goo.gl/maps/qUFtqvV2ZGRZZ8AFA" },
            { address: "Mathura Road, New Delhi", detailed_address: "B-1/H-5, Mohan Cooperative Industrial Estate, Mathura Road, New Delhi", location: "https://goo.gl/maps/HtifBeqgkEaP5RPw6" },
            { address: "Noida", detailed_address: "H-8, Sector 63, Noida", location: "https://goo.gl/maps/esT3zrAYGD7KAHFN8" }
          ],
          service: [
          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "INFINITY CARS",
      location: "Delhi",
      subLocation: "Delhi NCR",
      findUs: {
        bmw: {
          showroom: [
            { address: "Rajouri Garden, New Delhi", detailed_address: "Performance Cars Delhi C-5, Rajouri Garden, New Delhi", location: "https://goo.gl/maps/THLXGiY6qZvge6nLA" }
          ],
          service: [
            { address: "Mayapuri Industrial Area Phase 1, New Delhi", detailed_address: "Performance Cars Delhi Service B-41, Mayapuri Industrial Area Phase 1, New Delhi", location: "https://goo.gl/maps/jRW1e6Lh4xHwFmb77" },
          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    },
    {
      showroom: "KRISHNA AUTOMOBILES",
      location: "Punjab",
      subLocation: "Chandigarh, Ludhiana",
      findUs: {
        bmw: {
          showroom: [
            { address: "Chandigarh", detailed_address: "Plot No-125, Industrial Area Phase 1, Chandigarh", location: "https://goo.gl/maps/ksQCRZTgRScw4iZ48" },
            { address: "Ludhiana", detailed_address: "Dhandhari Khurd, GT Road, Ludhiana", location: "https://goo.gl/maps/Q9UWxHHJLVGu9PYc7" }
          ],
          service: [
          ]
        },
        mini: {
          showroom: [
            { address: "Chandigarh", detailed_address: "Plot No-125, Industrial Area Phase 1, Chandigarh", location: "https://goo.gl/maps/ksQCRZTgRScw4iZ48" }
          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "MUNICH MOTORS",
      location: "Chhattisgarh",
      subLocation: "Raipur",
      findUs: {
        bmw: {
          showroom: [
            { address: "Sarona, Chhattisgarh", detailed_address: "Ring Road Number 1, Sarona, Chhattisgarh , Raipur", location: "https://goo.gl/maps/qBzxJwnGTCYoGerj7" }
          ],
          service: [
          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "OSL PRESTIGE",
      location: "West Bengal",
      subLocation: "Kolkata",
      location2: "Orissa",
      subLocation2: "Cuttack",
      findUs: {
        bmw: {
          showroom: [
            { address: "Kolkata", detailed_address: "The Silver Arcade, 5, JBS Halden Avenue, EM Bypass, Kolkata", location: "https://goo.gl/maps/vaX8zx5rvLDGrQjo8" },
            { address: "Cuttack", detailed_address: "NH-5 Bhanpur, PO Gopalpur, Cuttack", location: "https://goo.gl/maps/MZqM2HwY9BMa2qNj9" },
            { address: "Kolkata_BPS", detailed_address: "Raikva 3A, Rammohan Mailick Garden Lane, Kolkata", location: "https://goo.gl/maps/vaX8zx5rvLDGrQjo8" },
          ],
          service: [
            { address: "PO Dhapa, Kolkata", detailed_address: "43, East Topsia Road, Arupota, PO Dhapa, Kolkata", location: "https://goo.gl/maps/t86XvTTRdd195mSv7" }
          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "SANGHI CLASSIC",
      location: "Rajasthan",
      subLocation: "Jaipur, Udaipur",
      findUs: {
        bmw: {
          showroom: [
            { address: "Jaipur", detailed_address: "137, Sanghi Garden Tonk Garden, Jaipur", location: "https://goo.gl/maps/b1NYaFA5UrvZTN9D7" },
            { address: "Udaipur", detailed_address: "E-95, Madri Industrial Area, Udaipur", location: "https://goo.gl/maps/4qQgkKd4y5qCYKdi9" }
          ],
          service: [

          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "SPEED MOTORWAGEN",
      location: "Uttar Pradesh",
      subLocation: "Kanpur, Lucknow",
      findUs: {
        bmw: {
          showroom: [
            { address: "Kanpur", detailed_address: "No-64, Government Industrial Estate, Kalpi Road, Kanpur", location: "https://goo.gl/maps/ukL9wwLbjrSkPqZW6" },
            { address: "Lucknow", detailed_address: "12.2 Kms Faizabad Road, Chinhat, Lucknow", location: "https://goo.gl/maps/zCqYpF5X7nRaHJiD8" }
          ],
          service: [

          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "TITANIUM AUTOS",
      location: "Jharkhand",
      subLocation: "Ranchi",
      findUs: {
        bmw: {
          showroom: [
            { address: "Ranchi", detailed_address: "NH-33, Chakla, Ormanjhi, Opposite Birsa Munda Zoological Park, Ranchi", location: "https://goo.gl/maps/fTYCociu1Y3zsVCu7" }
          ],
          service: [

          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "BIRD AUTOMOTIVE",
      location: "Uttarakhand",
      subLocation: "Dehradun",
      findUs: {
        bmw: {
          showroom: [
            { address: "Saharanpur Road", detailed_address: "Khasra No. 170 min. and 169 ka. Of Khata No. 1 in mauza Mohabewala, Tehsil Sader, on National Highway NH 72, Saharanpur Road, Dehradun.", location: "https://goo.gl/maps/Qz9Cr733jdWZtH3f7"}
          ],
          service: [
            { address: "Saharanpur Road", detailed_address: "Khasra No. 170 min. and 169 ka. Of Khata No. 1 in mauza Mohabewala, Tehsil Sader, on National Highway NH 72, Saharanpur Road, Dehradun.", location: "https://goo.gl/maps/Qz9Cr733jdWZtH3f7" }
          ]
        },
        mini: {
          showroom: [

          ],
          service: [

          ]
        }
      }
    }
    ],
    "02_WEST": [{
      showroom: "BAVARIA MOTORS",
      location: "Maharashtra",
      subLocation: "Aurangabad, Pune",
      location2: "Goa",
      subLocation2: "South Goa",
      findUs: {
        bmw: {
          showroom: [
            { address: "Camp, Pune", detailed_address: "CTS No-15 B, Wellesly Road, Besides Lal Deval, Camp, Pune", location: "https://g.page/BMWBavariaMotorsPune?share" },
            { address: "South Goa, Goa", detailed_address: "Plot No-2B, Phase 1A, Verna Industrial Area, Verna, Goa", location: "https://goo.gl/maps/Nstsih5hRwP5EfiK8" },
            { address: "Ashok Nagar, Aurangabad", detailed_address: "Plot No-P/1/2, Chikalthana, MIDC Industrial Area, Ashok Nagar, Aurangabad", location: "https://goo.gl/maps/KJNBqtDJY8rsuPyN8" }
          ],
          service: [
            { address: "Hadapsar, Pune", detailed_address: "Plot No-25, Near Wanowrie Police Station, Hadapsar Industrial Estate, Pune", location: "https://goo.gl/maps/HNTDoAz4RFnxak5a6" }
          ]
        },
        mini: {
          showroom: [
            { address: "South Goa, Goa", detailed_address: "Plot No-2B, Phase 1A, Verna Industrial Area, Verna, Goa", location: "https://goo.gl/maps/HNTDoAz4RFnxak5a6" }
          ],
          service: [
          ]
        }
      }
    }, {
      showroom: "EMINENT CARS",
      location: "Gujarat",
      subLocation: "Surat, Vadodra",
      findUs: {
        bmw: {
          showroom: [
            { address: "Dumas Road, Surat", detailed_address: "TP No-3 Rundh FP 38, Paike Sub Plot-3, Dumas Road, Surat", location: "https://goo.gl/maps/tjgUSbgi2WtY8niR8" },
            { address: "Sunpharma Road, Vadodra", detailed_address: "TP Scheme No-20, FP No-31, Opposite HDFC Bank, Sun pharma Road, Vadodra", location: "https://goo.gl/maps/jEQHKHXJ2ySdePWW7" }
          ],
          service: [
           
          ]
        },
        mini: {
          showroom: [
            
          ],
          service: [
          ]
        }
      }
    }, {
      showroom: "GALLOPS AUTOHAUS",
      location: "Gujarat",
      subLocation: "Ahmedabad, Rajkot",
      findUs: {
        bmw: {
          showroom: [
            { address: "Gandhinagar Highway, Ahmedabad", detailed_address: "Survey No-220, Sarkhej-Gandhinagar Highway, Ahmedabad", location: "https://goo.gl/maps/12nveA2kdgxhdYTK9" },
            { address: "AJI Crossroads, Rajkot", detailed_address: "AJI GIDC, AJI Crossroads, Rajkot", location: "https://goo.gl/maps/xJckYRvM7SWoCRLa8" }
          ],
          service: [
           
          ]
        },
        mini: {
          showroom: [
            { address: "Gandhinagar Highway, Ahmedabad", detailed_address: "Survey No-220, Sarkhej-Gandhinagar Highway, Ahmedabad", location: "https://goo.gl/maps/12nveA2kdgxhdYTK9" }
          ],
          service: [
          ]
        }
      }
    }, {
      showroom: "INFINITY CARS",
      location: "Maharashtra",
      subLocation: "Mumbai",
      location2: "Madhya Pradesh",
      subLocation2: "Indore",
      findUs: {
        bmw: {
          showroom: [
            { address: "Worli, Mumbai", detailed_address: "Lotus Building, Dr. Annie Besant Road, Opposite Nehru Centre, Worli, Mumbai", location: "https://g.page/bmw-infinitycars-showroom-worli?share" },
            { address: "Navi_Mumbai", detailed_address: "Plot No-50, Sector 1A, Juinagar, Navi Mumbai", location: "https://g.page/bmwinfinity-showroom-navi-mumbai?share" },
            { address: "Nariman_Point Mumbai", detailed_address: "Maker Chamber VI, 1-B, Ground Floor, Jamnalal Bajaj Road, Mumbai", location: "https://g.page/bmw-infinitycars-showroom-np?share" },
            { address: "Pipliya Kumar Devas Naka, Indore", detailed_address: "Unit No-17, Mangal Compound MR-11, Pipliya Kumar Devas Naka, Indore", location: "https://g.page/bmw-infinitycars-indore?share" },
          ],
          service: [
            { address: "Dr. E. Moses Road, Worli, Mumbai", detailed_address: "Mohatta Bhavan Compound, Plot No-3, Manjrekar Lane, Dr. E. Moses Road, Worli, Mumbai", location: "https://g.page/bmw-infinitycars-workshop-worli?share" },
            { address: "Turbhe, Navi Mumbai", detailed_address: "D-20/7, TTC Industrial Area, MIDC, Turbhe, Navi Mumbai", location: "https://g.page/bmw-infinitycars-workshop-turbhe?share" },
            { address: "Lalbaug, Mumbai", detailed_address: "Godrej & Boyce MFG. Co. Property, Plot No: 57 &58, Dattaram Khamkar Marg, near Gundecha Garden, Lalbaug, Mumbai", location: "https://g.page/bmw-infinitycars-service-lalbaug?share" }
          ]
        },
        mini: {
          showroom: [
            { address: "Worli, Mumbai", detailed_address: "Lotus Building, Dr. Annie Besant Road, Opposite Nehru Centre, Worli, Mumbai", location: "https://g.page/bmw-infinitycars-showroom-worli?share" },
            { address: "Nariman_Point Mumbai", detailed_address: "Maker Chamber VI, 1-B, Ground Floor, Jamnalal Bajaj Road, Mumbai", location: "https://g.page/bmw-infinitycars-showroom-np?share" },
            { address: "Santacruz, Mumbai", detailed_address: "Showroom No-1, Savoy Chambers, Ground Floor, Extension of Linking Road, Near Juhu Garden, Santacruz, Mumbai", location: "https://g.page/mini-cooper-showroom-mumbai?share" },
          ],
          service: [
          ]
        }
      }
    },  {
      showroom: "MUNICH MOTORS",
      location: "Maharashtra",
      subLocation: "Nagpur",
      findUs: {
        bmw: {
          showroom: [
            { address: "Hingna, Nagpur", detailed_address: "C-48 MIDC, Central MIDC Road, Near Toll Plaza, Hingna, Nagpur", location: "https://goo.gl/maps/Jw1EQUamEMoBJ6hg8" }
          ],
          service: [
           
          ]
        },
        mini: {
          showroom: [
            
          ],
          service: [
          ]
        }
      }
    }, {
      showroom: "NAVNIT MOTORS",
      location: "Maharashtra",
      subLocation: "Mumbai",
      findUs: {
        bmw: {
          showroom: [
            { address: "Andheri West, Mumbai", detailed_address: "C Wing, Waterford Building, CD Burfiwala Road, Juhu Lane, Andheri West, Mumbai", location: "https://goo.gl/maps/QAGsNezo4mqGKNH48" },
            { address: "Malad, Mumbai", detailed_address: "PMC/o Sasangi Engineering, Ramchandra Lane Junction, New Malad Link Road, Malad, Mumbai", location: "https://goo.gl/maps/mXtGYtgnsxPXYrri6" }
          ],
          service: [
            
            { address: "Andheri, Mumbai", detailed_address: "No-14B, Mahal Industrial Estate, Paperbox Compound, Andheri, Mumbai", location: "https://goo.gl/maps/kia4b4oxWf7PAuqK8" },
            { address: "Thane, Mumbai", detailed_address: "Navnit House, Gokul Nagar, Old Mumbai-Agra Road, Thane, Mumbai", location: "https://goo.gl/maps/hVw8frYPvFZoJxnx9" }
          ]
        },
        mini: {
          showroom: [
            
          ],
          service: [
          ]
        }
      }
    }
    ],
    "03_SOUTH": [
       {
        showroom: "DEUTSCHE MOTOREN",
        location: "Karnataka",
        subLocation: "Bengaluru",
        findUs: {
          bmw: {
            showroom: [
              { address: "Sadaramanagala, Bengaluru", detailed_address: "Ring Road Motors Bangalore 222/220A, Whitefield Road, Sadaramanagala, Bengaluru", location: "https://g.page/bmwbengaluru?share" }
            ],
            service: [
              { address: "Doddanekundi Industrial Area 2, Bengaluru", detailed_address: "Ring Road Motors Bangalore Service #23, 1st Cross Road, Doddanekundi Industrial Area 2, Bengaluru", location: "https://goo.gl/maps/j5LLvB9CPkUBQMwU7" }
            ]
          },
          mini: {
            showroom: [
              
            ],
            service: [
            ]
          }
        }
      }, {
      showroom: "EVM AUTOKRAFT",
      location: "Kerala",
      subLocation: "Kochi",
      findUs: {
        bmw: {
          showroom: [
            { address: "Kochi", detailed_address: "23/649, A1, Angel Plaza, NH-47, South Kalamassery, Kochi", location: "https://g.page/bmwevmautokraft?share" }
          ],
          service: [
          ]
        },
        mini: {
          showroom: [
            { address: "Kochi", detailed_address: "23/649, A1, Angel Plaza, NH-47, South Kalamassery, Kochi", location: "https://g.page/bmwevmautokraft?share" }
          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "KUN EXCLUSIVE",
      location: "Tamil Nadu",
      subLocation: "Chennai, Coimbatore, Madurai",
      findUs: {
        bmw: {
          showroom: [
            { address: "Meenabakkam, Chennai", detailed_address: "No-20, GST Road, Meenabakkam, Chennai", location: "https://g.page/bmwkunexclusive?share" },
            { address: "Coimbatore", detailed_address: "Door No-1/582-2, Avinashi Road, Neelambur, Arasur Post, Sulur Taluk, Coimbatore", location: "https://g.page/bmw-coimbatore?share" },
            { address: "Royapettah, Chennai", detailed_address: "Shop No- UGFD01, No-49, 50L, Whites Road, Express Avenue, Royapettah, Chennai", location: "https://g.page/bmwkunexclusive-chennaicity?share" },
            { address: "Thoppur Village, Madurai", detailed_address: "NH-7, Virudhunagar Road, Survey No-82/9A2 - 12-A, Thoppur Village, Madurai", location: "https://goo.gl/maps/UHwmiTWXS8dXu84d7" },
            { address: "Ambattur, Chennai", detailed_address: "B7 & B8, Ambattur Industrial Estate, Chennai", location: "https://g.page/bmw-service-center---ambattur?share" }
          ],
          service: [
            { address: "Ambattur, Chennai", detailed_address: "69,70, Industrial Estate, Ambattur", location: "https://g.page/BMWAmbatur?share" }
           
          ]
        },
        mini: {
          showroom: [
            { address: "Meenabakkam, Chennai", detailed_address: "No-20, GST Road, Meenabakkam, Chennai", location: "https://g.page/bmwkunexclusive?share" }
          ],
          service: [

          ]
        }
      }
    }, {
      showroom: "KUN EXCLUSIVE",
      location: "Telangana",
      subLocation: "Hyderabad",
      location2: "Andhra Pradesh",
      subLocation2: "Vijayawada",
      findUs: {
        bmw: {
          showroom: [
            { address: "Khairtabad, Hyderabad", detailed_address: "6-3 569, Opposite RTA Office, Khairtabad, Hyderabad", location: "https://goo.gl/maps/Q1tjcWCij3L7iFNp8" },
            { address: "Mangalagiri, Vijayawada", detailed_address: "Plot No-4, Industrial Park, Mangalagiri, Vijaywada", location: "https://goo.gl/maps/Cmj1zAKGCrCXhqmNA" },
            { address: "Jubilee Hills Main Road, Hyderabad", detailed_address: "Plot No.1 & 2 Survey No.403/1, Road No.1, Nandagiri Hills, Jubilee Hills Main Road, Hyderabad ", location: "https://goo.gl/maps/ckVvPciJVKYXZoi56" }
          ],
          service: [
            { address: "RTC Cross Roads, Hyderabad", detailed_address: "No#1-8-670, 3rd Gate, RTC Cross Roads, Hyderabad", location: "https://goo.gl/maps/Xx21sf11o6QLBuWVA" }
          ]
        },
        mini: {
          showroom: [
            { address: "Khairtabad, Hyderabad", detailed_address: "6-3 569, Opposite RTA Office, Khairtabad, Hyderabad", location: "https://goo.gl/maps/Q1tjcWCij3L7iFNp8" }
          ],
          service: [
          ]
        }
      }
    }, {
      showroom: "NAVNIT MOTORS",
      location: "Karnataka",
      subLocation: "Bengaluru",
      findUs: {
        bmw: {
          showroom: [
            { address: "Begur Hobli, Bengaluru", detailed_address: "No-4, Konnapana Agrahara, Near Electronic City, Hosur Road, Begur Hobli, Bengaluru", location: "https://g.page/bmw-navnit-motors-main-showroo?share" },
            { address: "Lavelle Road, Bengaluru", detailed_address: "No-2, Prestige Emerald Building, Madras Bank Road, Lavelle Road, Bengaluru", location: "https://g.page/bmw-navnit-motors-city-showroo?share" }
            // { address: "Kannur Village, Mangaluru", detailed_address: "No-48, Padil Kodekal, Kannur Village, Mangaluru", location: "https://goo.gl/maps/qm4yE3ZtwyjoLuMCA" }
          ],
          service: [
           
            { address: "Bangalore North, Bengaluru", detailed_address: "No-40/66, Sonnappana Halli, Bethahalasoor Post, New Airport Road, Bangalore North, Bengaluru", location: "https://g.page/bmw-service-center-north-bengalu?share" }
          ]
        },
        mini: {
          showroom: [
            { address: "Lavelle Road, Bengaluru", detailed_address: "No-2, Prestige Emerald Building, Madras Bank Road, Lavelle Road, Bengaluru", location: "https://g.page/bmw-navnit-motors-city-showroo?share" }
          ],
          service: [
          ]
        }
      }
    }, {
      showroom: "Varsha Autohaus",
      location: "Karnataka",
      subLocation: "Mangalore",
      findUs: {
        bmw: {
          showroom: [
            { address: "Old Kannuru Check Post, Mangalore", detailed_address: "D.No. 3-25/9, 3-25/10 and 3-25/1, Old Kannuru Check Post, Mangalore - Bangalore Highway, Mangalore, Karnataka - 575007", location: "https://goo.gl/maps/xz6dhzPhnQACVoS97" }
          ],
          service: [
            { address: "Old Kannuru Check Post, Mangalore", detailed_address: "D.No. 3-25/9, 3-25/10 and 3-25/1, Old Kannuru Check Post, Mangalore - Bangalore Highway, Mangalore, Karnataka - 575007", location: "https://goo.gl/maps/xz6dhzPhnQACVoS97" }
          ]
        },
        mini: {
          showroom: [
           
          ],
          service: [
          ]
        }
      }
    }
    ]
  }

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(findUs) {
    const dialogRef = this.dialog.open(LocationDetailsComponent, { data: findUs });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
