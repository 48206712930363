import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { SeoService } from 'src/app/common/services/seo.service';
import { ConstantsPipe } from 'src/app/pipes/constants.pipe';


@Component({
  selector: 'app-dealer-careers',
  templateUrl: './dealer-careers.component.html',
  styleUrls: ['./dealer-careers.component.scss']
})
export class DealerCareersComponent {
  baseUrl = "https://bmw.mynexthire.com/employer/jobs/careers#?src=careers&page=careers";
  iFrame = this.baseUrl;
  height = 900;
  isValid = false;

  locationDataList = [
    { value: false, lg: "Ahmedabad", lgId: 1, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Aurangabad", lgId: 2, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Bengaluru", lgId: 3, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Bhubaneshwar", lgId: 4, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Chandigarh", lgId: 5, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Chennai", lgId: 6, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Coimbatore", lgId: 7, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Cuttack", lgId: 8, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Delhi NCR", lgId: 9, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Goa", lgId: 10, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Hyderabad", lgId: 11, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Indore", lgId: 12, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Jaipur", lgId: 13, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Kanpur", lgId: 14, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Kochi", lgId: 15, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Kolkata", lgId: 16, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Lucknow", lgId: 17, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Ludhiana", lgId: 18, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Madurai", lgId: 19, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Mangalore", lgId: 20, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Mumbai", lgId: 21, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Nagpur", lgId: 22, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Pune", lgId: 23, availableBrand: ['', 'bmw', 'mini'] },
    { value: false, lg: "Raipur", lgId: 24, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Ranchi", lgId: 25, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Surat", lgId: 26, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Udaipur", lgId: 27, availableBrand: ['', 'bmw'] },
    { value: false, lg: "Vijaywada", lgId: 28, availableBrand: ['', 'bmw'] }
  ];

  locationList = this.locationDataList;

  pageVars = {
    showIframe: true,
    brand: undefined,
    department: undefined,
    locationGroups: [],
  };



  constructor(private router: Router, private activatedRoute: ActivatedRoute, private seoService: SeoService, private constants: ConstantsPipe) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      this.seoService.updateMetaTags(seoData['metaTags']);
      this.seoService.updateTitle(seoData['title']);
    });
    this.pageVars.brand = 'brand-all';
    this.pageVars.department = 'department-all';

    // this.baseUrl = this.constants.transform('instance') + "#?src=careers&page=careers";
    let url = window.location.href;
    if (url.includes('?')) {
      this.iFrame = this.constants.transform('instance') + "/employer/jobs/careers#?" + url.split('?')[1];
    }
    window.addEventListener("message", (message) => {
      this.receiveMessage(message.data);
    }, false)
  }

  receiveMessage(msg) {
    switch (true) {
      case (msg.search("http") > -1):
        document.location.href = msg, true;
        setTimeout(t => {
          this.pageVars.showIframe = true;
        }, 50);
        break;

      case (msg.search("height->change") > -1):
        // var height = msg.substring(("height->change").length);
        // var element = document.getElementById('mnhembedded');
        // var mnh_disable_autoscroll = element.getAttribute("mnh-disable-autoscroll");
        // try {
        //     if(!mnh_disable_autoscroll){
        //         element.scrollIntoView({ behavior: 'smooth', block:'start'});
        //     }
        // } catch (error) {}
        this.height = (parseInt(msg) + 30);
        break;

      // case (msg.search("scroll->top") > -1) :
      //     try {
      //         var element = document.getElementById('mnhembedded');
      //         element.scrollIntoView({ behavior: 'smooth', block:'start'});
      //     } catch (error) {}
      //     // scroll(0, 0);
      //     break;
    }
  }

  fetchCheckedIDs() {
    this.pageVars.locationGroups = [];
    this.locationDataList.forEach((item, index) => {
      if (item.value) {
        this.pageVars.locationGroups.push(item.lg);
      }
    });
    this.updateFilters();
  }

  updateFilters() {
    this.pageVars.showIframe = false;
    let suffix = "";
    if (this.pageVars.brand && this.pageVars.brand != 'brand-all') {
      suffix += "&Brand=" + this.pageVars.brand;
    }
    if (this.pageVars.department && this.pageVars.department != 'department-all') {
      suffix += "&Department=" + this.pageVars.department;
    }
    if (this.pageVars.locationGroups && this.pageVars.locationGroups.length > 0) {
      suffix += "&locationgroup=" + this.pageVars.locationGroups.join(",");
    }
    this.iFrame = this.baseUrl + encodeURI(suffix);
    setTimeout(t => {
      this.pageVars.showIframe = true;
    }, 50);
  }

  removeFilters() {
    this.locationDataList.forEach(child => {
      child.value = false
    })
    this.pageVars.locationGroups = [];
    this.updateFilters();
  }
  filterBrand(brand) {
    this.locationList = [];
    this.locationDataList.forEach((item, index) => {
      if (item.availableBrand.includes(brand)) {
        this.locationList.push(item);
      }
    })
  }
  disableDepartment() {
    this.isValid = true;
    this.removeFilters();
    this.pageVars.department = 'department-all';
  }
  enabledDepartment() {
    this.isValid = false;
    this.removeFilters();
  }

  // minisidebar for filter
  showMiniSidebar: boolean = false;

}
