<section class="page-top-padding">
    <div class="container">
        <div class="row pt-md-4 pb-md-5 pb-5 px-md-0 px-3">
            <div class="col-md-12">
                <div class="sign-up-form">
                    <h1>SIGN UP FOR ALERTS</h1>
                    <div>

                        <lable for="name">Name:</lable><br>
                        <input type="text" name="name" id="name" [(ngModel)]="name">
                        <br>
                        <lable for="email">Email:</lable><br>
                        <input type="email" name="email" id="email" [(ngModel)]="email" (focusout)="checkEmail()"><br>
                        <lable for="frequency">Frequency:</lable><br>
                        <select id="frequency" [(ngModel)]="frequency">
                            <option value="" selected="" disabled="" hidden="">Please Select...</option>
                            <option> WEEKLY</option>
                            <option> BI-WEEKLY</option>
                        </select><br>

                        <lable for="brand">Brand:</lable><br>
                        <select id="brand" [(ngModel)]="brand" (ngModelChange)="DisableMenu()">
                            <option value="" selected="" disabled="" hidden="">Please Select...</option>
                            <option value="bmw"> BMW</option>
                            <option value="mini"> MINI</option>
                        </select><br>

                        <lable for="jobCategories">Job Category:</lable><br>
                        <select id="jobCategories" [(ngModel)]="jobCategories" [disabled]="isMini">
                            <option value="" selected="" disabled="" hidden="">Please Select...</option>
                            <option> SALES</option>
                            <option> MANAGEMENT</option>
                            <option> AFTERSALES (NON-TECHNICAL)</option>
                            <option> AFTERSALES (TECHNICAL)</option>
                            <option> CRM AND MARKETING </option>
                            <option> HR AND ADMIN </option>

                        </select><br>
                        <lable for="locations">Locations:</lable><br>
                        <select id="locations" [(ngModel)]="locations">
                            <option value="" selected="" disabled="" hidden="">Please Select....</option>
                            <option>Any</option>
                            <option>Ahmedabad</option>
                            <option>Aurangabad</option>
                            <option>Bengaluru</option>
                            <option>Bhubaneshwar</option>
                            <option>Chandigarh</option>
                            <option>Chennai</option>
                            <option>Coimbatore</option>
                            <option>Cuttack</option>
                            <option>Delhi NCR</option>
                            <option>Goa</option>
                            <option>Hyderabad</option>
                            <option>Indore</option>
                            <option>Jaipur</option>
                            <option>Kanpur</option>
                            <option>Kochi</option>
                            <option>Kolkata</option>
                            <option>Lucknow</option>
                            <option>Ludhiana</option>
                            <option>Madurai</option>
                            <option>Mangalore</option>
                            <option>Mumbai</option>
                            <option>Nagpur</option>
                            <option>Pune</option>
                            <option>Raipur</option>
                            <option>Ranchi</option>
                            <option>Surat</option>
                            <option>Udaipur</option>
                            <option>Vijaywada </option>

                        </select>
                        <br><br>
                        <input type="submit" value="CREATE ALERT" class="button" (click)="loadBadge()">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
