<section class="page-top-padding" onload="getUrlVars()">
    <div class="container-fluid">
        <div class="row px-3 pb-3">
            <div class="col-lg-3 col-md-4 career-mr-bottom">
                <div class="filter-block desktop-view-filter">
                    <div>
                        <button type="button" class="btn btn-filter">See All Open Jobs</button>
                    </div>
                    <div class="filter-selection">
                        <!-- {{iFrame}} -->
                        <div class="selection-block">
                            <h3 class="filter-name">FILTER</h3>
                            <div class="filter-title">
                                <h5>Brand</h5>
                                <!-- <span class="filter-apply-btn d-flex align-items-center">
                                    <a (click)="updateFilters()">Apply</a>
                                </span>  -->
                            </div>

                            <input class="" type="radio" value="brand-all" id="brand-all" [(ngModel)]="pageVars.brand"
                                (ngModelChange)="filterBrand(''); updateFilters()" (click)="enabledDepartment()">
                            <label for="brand-all" style="margin-left:5px;">All</label>
                            <br>
                            <input class="" type="radio" value="bmw" id="brand-bmw" [(ngModel)]="pageVars.brand"
                                (ngModelChange)="filterBrand('bmw'); updateFilters()" (click)="enabledDepartment()">
                            <label for="brand-bmw" style="margin-left:5px;">BMW</label>
                            <br>
                            <input type="radio" value="mini" id="brand-mini" [(ngModel)]="pageVars.brand"
                                (ngModelChange)="filterBrand('mini'); updateFilters();" (click)="disableDepartment()">
                            <label for="brand-mini" style="margin-left:5px;">MINI</label><br>
                        </div>


                        <div class="selection-block">
                            <div class="filter-title">
                                <h5>Department</h5>
                                <!-- <span class="filter-apply-btn d-flex align-items-center">
                                    <a (click)="updateFilters()">Apply</a>
                                </span>  -->
                            </div>
                            <input type="radio" name="department-all" value="department-all" id="department-all"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()">
                            <label for="department-all" style="margin-left:5px;">All</label><br>
                            <input type="radio" name="management" value="management" id="management"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="management" style="margin-left:5px;">Management</label><br>
                            <input type="radio" name="sales" value="sales" id="sales" [(ngModel)]="pageVars.department"
                                (ngModelChange)="updateFilters()" [disabled]="isValid">
                            <label for="sales" style="margin-left:5px;">Sales</label><br>
                            <input type="radio" name="aftersales" value="aftersales" id="aftersales"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="aftersales" style="margin-left:5px;">Aftersales</label><br>
                            <input type=radio name="technical" value="technical" id="technical"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="technical" style="margin-left:5px;">Technical</label><br>
                            <input type=radio name="marketing+and+crm" value="marketing+and+crm" id="crm"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="crm" style="margin-left:5px;">Marketing and CRM </label><br>
                            <input type=radio name="hr+and+administration" value="hr+and+administration" id="hr"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="hr" style="margin-left:5px;">HR and Administration</label><br>


                        </div>
                        <div class="filter-title filter-location-title">
                            <h5>Location</h5>

                            <span class="location-btn-block d-flex align-items-center">
                                <a (click)="removeFilters()">Clear</a>
                                <!-- <a (click)="updateFilters()">Apply</a> -->
                            </span>
                        </div>
                        <div class="location">
                            <ng-container *ngFor="let location of locationList">
                                <!-- (click)="fetchSelectedItems()" -->
                                <input id="{{location.lg}}" type="checkbox" [(ngModel)]="location.value"
                                    (ngModelChange)="fetchCheckedIDs()">
                                <label for="{{location.lg}}" style="margin-left:5px;">{{location.lg}}</label>
                                <br>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- --------------------mobile-view-filter----------------------->

                <div class="filter-block filter-left-side-responsive" *ngIf="showMiniSidebar">
                    <div class="see-all-job-section">
                        <button type="button" class="btn btn-filter">See All Open Jobs</button>
                        <div class="filter-close-btn">
                            <a class="btn-link" (click)="showMiniSidebar=!showMiniSidebar"> <i
                                    class="fas fa-times"></i>&nbsp;
                                Close</a>
                        </div>
                    </div>
                    <div class="filter-selection">
                        <!-- {{iFrame}} -->
                        <div class="selection-block">
                            <h2 class="filter-name">FILTER</h2>
                            <div class="filter-title">
                                <h5>Brand</h5>
                                <!-- <span class="filter-apply-btn d-flex align-items-center">
                                    <a (click)="updateFilters()">Apply</a>
                                </span>  -->
                            </div>

                            <input class="" type="radio" value="brand-all" id="brand-all" [(ngModel)]="pageVars.brand"
                                (ngModelChange)="filterBrand(''); updateFilters()" (click)="enabledDepartment()">
                            <label for="brand-all" style="margin-left:5px;">All</label>
                            <br>
                            <input class="" type="radio" value="bmw" id="brand-bmw" [(ngModel)]="pageVars.brand"
                                (ngModelChange)="filterBrand('bmw'); updateFilters()" (click)="enabledDepartment()">
                            <label for="brand-bmw" style="margin-left:5px;">BMW</label>
                            <br>
                            <input type="radio" value="mini" id="brand-mini" [(ngModel)]="pageVars.brand"
                                (ngModelChange)="filterBrand('mini'); updateFilters();" (click)="disableDepartment()">
                            <label for="brand-mini" style="margin-left:5px;">MINI</label><br>
                        </div>


                        <div class="selection-block">
                            <div class="filter-title">
                                <h5>Department</h5>
                                <!-- <span class="filter-apply-btn d-flex align-items-center">
                                    <a (click)="updateFilters()">Apply</a>
                                </span>  -->
                            </div>
                            <input type="radio" name="department-all" value="department-all" id="department-all"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()">
                            <label for="department-all" style="margin-left:5px;">All</label><br>
                            <input type="radio" name="management" value="management" id="management"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="management" style="margin-left:5px;">Management</label><br>
                            <input type="radio" name="sales" value="sales" id="sales" [(ngModel)]="pageVars.department"
                                (ngModelChange)="updateFilters()" [disabled]="isValid">
                            <label for="sales" style="margin-left:5px;">Sales</label><br>
                            <input type="radio" name="aftersales" value="aftersales" id="aftersales"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="aftersales" style="margin-left:5px;">Aftersales</label><br>
                            <input type=radio name="technical" value="technical" id="technical"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="technical" style="margin-left:5px;">Technical</label><br>
                            <input type=radio name="marketing+and+crm" value="marketing+and+crm" id="crm"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="crm" style="margin-left:5px;">Marketing and CRM </label><br>
                            <input type=radio name="hr+and+administration" value="hr+and+administration" id="hr"
                                [(ngModel)]="pageVars.department" (ngModelChange)="updateFilters()"
                                [disabled]="isValid">
                            <label for="hr" style="margin-left:5px;">HR and Administration</label><br>


                        </div>
                        <div class="filter-title filter-location-title">
                            <h5>Location</h5>

                            <span class="location-btn-block d-flex align-items-center">
                                <a (click)="removeFilters()">Clear</a>
                                <!-- <a (click)="updateFilters()">Apply</a> -->
                            </span>
                        </div>
                        <div class="location">
                            <ng-container *ngFor="let location of locationList">
                                <!-- (click)="fetchSelectedItems()" -->
                                <input id="{{location.lg}}" type="checkbox" [(ngModel)]="location.value"
                                    (ngModelChange)="fetchCheckedIDs()">
                                <label for="{{location.lg}}" style="margin-left:5px;">{{location.lg}}</label>
                                <br>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-9 col-md-8 px-md-3 px-1 bg-white career-mr-bottom">
                <div>
                    <span class="btn-link filter-menu-btn" (click)="showMiniSidebar=!showMiniSidebar">
                        <i class="fas fa-filter"></i> Filter</span>
                </div>
                <div class="career-content career-content mt-3 ml-3">
                    <h1>
                        List of multi - location Dealer Jobs in India​
                    </h1>
                    <h2 class="sub-title">Know BMW Multi Job Opportunities in India</h2>
                </div>
                <script src="https://bmw.mynexthire.com/employer/ui/js/jobboard/careers-integration.js"></script>


                <script> document.onreadystatechange = function (e) { if (document.readyState === "complete") { mnh_ci_onreadystatechange("careers", "bmw"); } };</script>
                <iframe *ngIf="pageVars.showIframe" [style.height.px]="height"
                    class="col-xs-12 panel panel-default career-iframe" mnh-jd-list-view="table" id="mnhembedded"
                    [src]="iFrame | safe">
                </iframe>
            </div>
        </div>
    </div>
</section>

<!-- <script>
    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) { vars[(key + "").toLowerCase()] = (value + "").toLowerCase(); });

        (document.getElementById("brand-all")).checked = true;
        (document.getElementById("department-all")).checked = true;

        var mySections = {
            "brand": ["brand-all", "bmw", "mini"],
            "department": ["department-all", "mini", "management", "sales", "aftersales", "technical", "crm+and+marketing", "hr+and+admin"],
            "locationgroup": ["ahmedabad", "aurangabad", "bengaluru", "bhubaneshwar", "chandigarh", "chennai", "coimbatore", "cuttack", "delhi ncr", "goa", "hyderabad", "indore", "jaipur", "kanpur", "kochi", "kolkata", "lucknow", "ludhiana", "madurai", "mangalore", "mumbai", "nagpur", "pune", "raipur", "ranchi", "surat", "udaipur", "vijaywada"]
        };

        for (var param in vars) {
            for (var section in mySections) {
                for (var i = 0; i < mySections[section].length; i++) {
                    var paramList = (vars[param] + "").split(",");
                    console.log(paramList);
                    for (var j = 0; j < paramList.length; j++) {
                        if (paramList[j] == mySections[section][i]) {
                            var theElement = document.getElementById(mySections[section][i]);
                            if (theElement) {
                                theElement.checked = true;
                            }
                        }
                    }
                }
            }
        }
    }

    function searchNow() {
        var mySections = {
            "brand": ["brand-all", "bmw", "mini"],
            "department": ["department-all", "mini", "management", "sales", "aftersales", "technical", "crm+and+marketing", "hr+and+admin"],
            "locationgroup": ["ahmedabad", "aurangabad", "bengaluru", "bhubaneshwar", "chandigarh", "chennai", "coimbatore", "cuttack", "delhi ncr", "goa", "hyderabad", "indore", "jaipur", "kanpur", "kochi", "kolkata", "lucknow", "ludhiana", "madurai", "mangalore", "mumbai", "nagpur", "pune", "raipur", "ranchi", "surat", "udaipur", "vijaywada"]
        };

        var searchCategories = {};
        for (var section in mySections) {
            for (var i = 0; i < mySections[section].length; i++) {
                if (mySections[section][i] != (section.toLowerCase() + "-all")) {
                    var theElement = document.getElementById(mySections[section][i]);
                    if (theElement && theElement.checked == true) {
                        if (searchCategories[section]) {
                            searchCategories[section].push(mySections[section][i]);
                        } else {
                            searchCategories[section] = [mySections[section][i]];
                        }
                    }
                }
            }
        }

        var suffix = "";
        if (searchCategories.brand && searchCategories.brand.length > 0) {
            suffix += "&brand=" + searchCategories.brand[0];
        }
        if (searchCategories.department && searchCategories.department.length > 0) {
            suffix += "&department=" + searchCategories.department[0];
        }
        if (searchCategories.locationgroup && searchCategories.locationgroup.length > 0) {
            suffix += "&locationgroup=" + searchCategories.locationgroup.join(",");
        }

        location.href = "http://54.213.156.132/dealer-careers/?src=careers" + encodeURI(suffix);
    }

</script> -->