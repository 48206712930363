<section class="bg-white">
    <section class="bmw-slider">
        <carousel>
            <slide class="carousel-inner">
                <div class="slider-zoom">
                    <img src="/assets/images/Banner5.jpg" class="w-100" alt="BMW slides">
                    <div class="carousel-caption">
                        <div class="cssanimation fadeInBottom-caption">
                            <h1>
                                Launch Your Dream Job at ​<span class="d-lg-block d-inline">
                                    BMW Group Dealer Network</span>
                            </h1>
                            <p>Accelerate your career at BMW Group Retail Network​</p>
                        </div>
                    </div>
                </div>
            </slide>
            <slide class="carousel-inner">
                <div>
                    <img src="/assets/images/Banner2.jpg" class="w-100" alt="BMW slides">
                </div>
            </slide>
            <slide class="carousel-inner">
                <div class="slider-zoom">
                    <img src="/assets/images/banner11.jpg" class="w-100" alt="BMW slides">
                    <div class="carousel-caption">
                        <div class="cssanimation fadeInBottom-caption">
                            <h1>
                                BMW Group Dealership ​<span class="d-lg-block d-inline">Job Portal</span>
                            </h1>
                            <p>Accelerate your career at BMW Group Retail Network​</p>
                        </div>
                    </div>
                </div>
            </slide>
            <slide class="carousel-inner">
                <div>
                    <img src="/assets/images/Banner4.jpg" class="w-100" alt="BMW slides">
                </div>
            </slide>
            <slide class="carousel-inner">
                <div>
                    <!-- <div class="slider-zoom-in"> -->
                    <img src="/assets/images/Banner1.jpg" class="w-100" alt="BMW slides">
                </div>
            </slide>
            <!-- <slide class="carousel-inner">
                <div class="slider-zoom-in">
                    <img src="/assets/images/Banner7.jpg" class="w-100" alt="BMW slides">
                </div>
            </slide> -->
        </carousel>
    </section>

    <section>
        <div class="container mt-5">
            <div class="row search-block-row">
                <div class="col-md-4 col-6 search-block">
                    <div class="form-group">
                        <input type="text" class="form-control search-input" id="keyword" [(ngModel)]="pageVars.kw"
                            placeholder="Keyword">
                    </div>
                </div>
                <div class="col-md-4 col-6 search-block">
                    <div class="form-group">
                        <input type="text" class="form-control search-input" id="location" [(ngModel)]="pageVars.loc"
                            placeholder="Location">
                    </div>
                </div>
                <div class="col-md-4 col-12 search-block">
                    <div class="form-group">
                        <button type="button" class="btn search-btn w-100" (click)="searchReqs()">SEARCH</button>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row mt-5 mb-4">
                <div class="col-md-6">
                    <div class="decription">
                        <h2>
                            EXPERIENCE THE DIFFERENCE
                        </h2>
                        <p>
                            Our dealership employees are passionate for the BMW Group’s world-famous brands and take
                            pride in the quality of our design, technology and engineering. A diverse culture and
                            workforce will catalyze your career growth as you get an
                            opportunity to work for the dealer
                            network of one of the leading brands in the premium automotive sector. Here, you
                            will be
                            exposed to global standards in retail, along with industry-best training facilities, for an
                            unparalleled learning experience and opportunities to grow. If you’re passionate about
                            changing the way the world moves for good then there’s never been a more exciting time to be
                            part of the BMW Group dealer network.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="/assets/images/Homepage_Exp.jpg" class="img-fluid" style="margin-top: 0.5rem;">
                </div>
            </div>
            <hr class="hr-line">
            <div class="row mt-4 mb-5">
                <div class="col-md-12">
                    <div class="decription">
                        <h2>
                            EXPLORE A REWARDING CAREER
                        </h2>
                        <p>
                            Our prestigious brands are best-of-breed and globally acclaimed, and it’s our pride in the
                            quality of our design, technology, and engineering, that sets us apart. With multiple
                                dealership vacancies, the BMW Group Dealer Jobs is a network and talent pool
                            which
                            facilitates connection between skilled candidates and dealerships. Whether you are a skilled
                                Technician, an experienced Service Advisor or a passionate Sales Consultant, we are
                                ready to
                                welcome you to work with one of our dealership in the retail network.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="car-category">
                        <a routerLink="/bmw">
                            <img src="/assets/images/career_bmw.jpg" class="img-fluid">
                            <p class="category-brand-bmw">BMW</p>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="car-category d-flex align-items-end flex-column">
                        <a routerLink="/mini">
                            <img src="/assets/images/ExploreMINI.jpg" class="img-fluid">
                            <p class="category-brand-mini">MINI</p>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </section>
</section>