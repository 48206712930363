<mat-tab-group dynamicHeight>
  <mat-tab label="BMW">
    <div class="mat-elevation-z4">
      <div *ngIf="data.bmw.showroom && data.bmw.showroom.length > 0">
         <h5 class="bmw-popup-title">Showroom</h5>
      </div>
      <section class="bmw-group-popup">
        <div class="row" *ngIf="data.bmw.showroom && data.bmw.showroom.length > 0">
          <div class="col-lg-4 col-md-6 col-sm-12 padding-0 d-flex" *ngFor="let showroom of data.bmw.showroom">
            <div class="card bmw-card align-items-stretch">
              <div class="card-header bmw-cardheader">
                <h5>{{showroom.address}}</h5>
              </div>
              <div class="card-body">
                <p class="card-text">{{showroom.detailed_address}}</p>
                <a href="{{showroom.location}}" target="_blank">View Location</a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="!data.bmw.showroom || data.bmw.showroom.length < 1">
          <h5 class="location-available">No Showroom Available</h5>
        </div> -->
      </section>
      <div *ngIf="data.bmw.service && data.bmw.service.length > 0">
        <h5 class="bmw-popup-title">Service</h5>
      </div>
      <section class="bmw-group-popup">
        <div class="row" *ngIf="data.bmw.service && data.bmw.service.length > 0">
          <div class="col-lg-4 col-md-6 col-sm-12 padding-0 d-flex" *ngFor="let service of data.bmw.service">
            <div class="card bmw-card align-items-stretch">
              <div class="card-header bmw-cardheader">
                <h5>{{service.address}}</h5>
              </div>
              <div class="card-body">
                <p class="card-text">{{service.detailed_address}}</p>
                <a href="{{service.location}}" target="_blank">View Location</a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="!data.bmw.service || data.bmw.service.length < 1">
          <h5 class="location-available">No Service Available</h5>
        </div> -->
      </section>
    </div>
  </mat-tab>
  <mat-tab *ngIf="data.mini.showroom && data.mini.showroom.length > 0" label="MINI">
    <div class="mat-elevation-z4">
      <div *ngIf="data.mini.showroom && data.mini.showroom.length > 0">
        <h5 class="bmw-popup-title">Showroom</h5>
      </div>
      <section class="bmw-group-popup">
        <div class="row" *ngIf="data.mini.showroom && data.mini.showroom.length > 0">
          <div class="col-lg-4 col-md-6 col-sm-12 padding-0 d-flex" *ngFor="let showroom of data.mini.showroom">
            <div class="card bmw-card align-items-stretch">
              <div class="card-header bmw-cardheader">
                <h5>{{showroom.address}}</h5>
              </div>
              <div class="card-body">
                <p class="card-text">{{showroom.detailed_address}}</p>
                <a href="{{showroom.location}}" target="_blank">View Location</a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="!data.mini.showroom || data.mini.showroom.length < 1">
          <h5 class="location-available">No Showroom Available</h5>
        </div> -->
      </section>
      <div *ngIf="data.mini.service && data.mini.service.length > 0">
        <h5 class="bmw-popup-title">Service</h5>
      </div>
        <section class="bmw-group-popup">
        <div class="row" *ngIf="data.mini.service && data.mini.service.length > 0">
          <div class="col-lg-4 col-md-6 col-sm-12 padding-0 d-flex" *ngFor="let service of data.mini.service">
            <div class="card bmw-card align-items-stretch">
              <div class="card-header bmw-cardheader">
                <h5>{{service.address}}</h5>
              </div>
              <div class="card-body">
                <p class="card-text">{{service.detailed_address}}</p>
                <a href="{{service.location}}" target="_blank">View Location</a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="!data.mini.service || data.mini.service.length < 1">
          <h5 class="location-available">No Service Available</h5>
        </div> -->
      </section>
    </div>
  </mat-tab>
</mat-tab-group>