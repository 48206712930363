import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bmw',
  templateUrl: './bmw.component.html',
  styleUrls: ['./bmw.component.scss']
})
export class BmwComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
