import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BmwComponent } from './components/bmw/bmw.component';
import { DealerCareersComponent } from './components/dealer-careers/dealer-careers.component';
import { DealerLocationsComponent } from './components/dealer-locations/dealer-locations.component';
import { LandingComponent } from './components/landing/landing.component';
import { LegalDocumentComponent } from './components/legal-document/legal-document.component';
import { MiniComponent } from './components/mini/mini.component';
import { SignUpAlertComponent } from './components/sign-up-alert/sign-up-alert.component';
import { TrainingDevelopmentComponent } from './components/training-development/training-development.component';

const routes: Routes = [
  { path: 'bmw', component: BmwComponent },
  {
    path: 'dealer-careers', component: DealerCareersComponent, data: {
      seo: {
        title: "Find BMW sales Jobs, Dealers & service Advisor Jobs In India",
        metaTags: [
          { name: 'description', content: "BMW has a sales consultant, dealers job & service advisor jobs in Mumbai, Bangalore, & Delhi, India. Contact us to know about a multi-location opening in India." },
          { name: 'keywords', content: "Get BMW Dealers, sale, & Service Consultant Jobs In India" },
        ]
      }
    }
  },
  { path: 'dealer-locations', component: DealerLocationsComponent },
  {
    path: 'landing', component: LandingComponent, data: {
      seo: {
        title: "BMW Dealership Job, Sales Career, Service Advisor Job",
        metaTags: [
          { name: 'description', content: "BMW is offering a dealership job, sales career, or service advisor job at their showroom. Contact us if you're a passionate manager, leader or technical expert." },
          { name: 'keywords', content: "Get the BMW Dealership jobs in the nearest showroom, BMW Sales jobs, BMW Dealership Jobs near me,BMW Sales jobs, bmw jobs" },
        ]
      }
    }
  },
  { path: 'mini', component: MiniComponent },
  {
    path: 'training-development', component: TrainingDevelopmentComponent, data: {
      seo: {
        title: "BMW Group training center in India, BMW Training Academy",
        metaTags: [
          { name: 'description', content: "BMW group training center enhances and develops its employees' abilities and offers a comprehensive range of training courses and role-specific Certifications." },
          { name: 'keywords', content: "BMW Dealer & Advisor Traning Center" },
        ]
      }
    }
  },
  { path: 'sign-up-alert', component: SignUpAlertComponent },
  { path: 'legal-document', component: LegalDocumentComponent },
  { path: '**', component: LandingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
