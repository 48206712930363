<section class="bmw-footer">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-3">
                <div class="footer-block">
                    <h5>Visit us</h5>
                    <ul>
                        <li><a href="https://www.bmw.in/en/index.html" target="_blank"> BMW </a></li>
                        <li><a href="https://www.mini.in/en_IN/home.html" target="_blank">MINI </a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-block">
                    <h5>LINKS</h5>
                    <ul >
                        <li><a routerLink="/dealer-careers">Explore Current Opportunities</a></li>
                        <li><a routerLink="/bmw">Explore BMW Dealer Careers</a></li>
                        <li><a routerLink="/mini">Explore MINI Dealer Careers</a></li>
                        <li><a routerLink="/dealer-locations">Dealer Location</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-block">
                    <h5>Connect with us</h5>
                    <p>BMW</p>
                    <div class="social-icons">
                        <a href="https://www.facebook.com/bmwindia" target="_blank" class="facebook-bg">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://twitter.com/bmwindia" target="_blank" class="twitter-bg">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/bmw-india/" target="_blank" class="linkedin-bg">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a href="https://www.instagram.com/bmwindia_official/?hl=en" target="_blank"
                            class="instagram-bg">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                    <p class="mt-2">MINI</p>
                    <div class="social-icons">
                        <a href="https://www.facebook.com/MINI.India" target="_blank" class="facebook-bg">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://twitter.com/MINIOfficial_IN" target="_blank" class="twitter-bg">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/miniindiaofficial/" target="_blank"
                            class="linkedin-bg">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a href="https://www.instagram.com/miniindia_official/?hl=en" target="_blank"
                            class="instagram-bg">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-block">
                    <h5>Legal</h5>
                    <ul>
                        <li><a routerLink="/legal-document">Terms and Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>