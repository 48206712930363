<section class="bmw-slider">
    <div class="page-header-img">
        <img src="/assets/images/Career-at-MINI.jpg" class="w-100" alt="BMW slides">
    </div>
</section>
<!-- <section class="nav-header">
    <div class="mini-header">
    </div>
</section> -->

<section>
    <div class="container">
        <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
            <div class="col-lg-6 col-md-12 order-lg-1 order-2">
                <div class="decription">
                    <h1>
                        ABOUT THE BRAND
                    </h1>
                    <p>
                        MINI is iconic, made timeless through its heritage. MINI is inventive and progressive,
                        it blends creativity with cleverness, offering an open platform for people, design and a
                        vibrant urban life. It embraces diverse personalities, connecting like-minded individuals
                        forming a unique tribe. MINI’s spirited attitude to life inspires people.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-2 order-1">
                <div class="decription-img">
                    <img src="/assets/images/AboutMinI.jpg" class="img-fluid">
                </div>
            </div>
        </div>
        <hr class="hr-line">
        <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
            <div class="col-lg-6 col-md-12">
                <div class="decription-img">
                    <img src="/assets/images/MINI-Who-are-we-looking-for.jpg" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="decription">
                    <h1>
                        Who Are We Looking For
                    </h1>
                    <p>
                        When you join MINI Dealer, you don't just join a Dealership,
                        you become part of a culture and a lifestyle. At MINI, we spark individuality to
                        uplift people’s lives. You will work with an iconic Brand, experience products that
                        are head turners and will also interact with amazing individuals. People who are
                        passionate, ambitious, creative and have a progressive mindset, a high energy
                        environment awaits at MINI.
                    </p>
                </div>
            </div>
        </div>
        <hr class="hr-line">
        <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
            <div class="col-md-12">
                <h1 class="career_type">DEALER ROLES</h1>
            </div>

            <div class="col-lg-6 col-md-12  order-lg-1 order-2">
                <div class="decription">
                    <h2>
                        MINI Marketing Manager
                    </h2>
                    <p>
                        MINI Marketing Manager will act as the Brand Custodian, support the Dealership, ensure brand
                        identity compliance and a superior customer and brand experience. An expert in analyzing
                        consumer insights and market trends, the MINI Marketing Manager should be able to create
                        strategies for greater market penetration with a digital-first approach.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12  order-lg-2 order-1">
                <div class="decription-img">
                    <img src="/assets/images/MINI-MARKETING-MANAGER.jpg" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
            <div class="col-lg-6 col-md-12">

                <div class="decription-img">
                    <img src="/assets/images/MINI-Sales-Consultant.jpg" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="decription">
                    <h2>
                        MINI Sales Consultant
                    </h2>
                    <p>
                        As the primary point of contact at the Dealership, mission of a MINI Sales Consultant will be to
                        turn potential customers into MINI owners. The person will be responsible for achievement of
                        sales objectives and targets through customer consulting, negotiation and closure. Individuals
                        in this role are motivated, always willing to put in extra effort to provide customers one-of-a
                        kind experience.
                    </p>
                </div>
            </div>
        </div>

        <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
            <div class="col-lg-6 col-md-12 order-lg-1 order-2">
                <div class="decription">
                    <h2>
                        MINI Genius
                    </h2>
                    <p>
                        The MINI Genius plays a key role to turn a prospect customer into a MINI fan. His passion for
                        MINI sets him apart as he knows a MINI inside out. He interacts with customers about the unique
                        product features. The Genius embodies the unique MINI vibe and has a passion for top-notch
                        customer communication
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-2 order-1">
                <div class="decription-img">
                    <img src="/assets/images/MINI-Genius.jpg" class="img-fluid">
                </div>
            </div>
        </div>

    </div>
</section>
<a routerLink="/dealer-careers" type="button" class="btn btn-secondary explore-btn">EXPLORE CURRENT JOB VACANCIES</a>