<section class="bmw-slider">
        <div class="page-header-img">
                <img src="/assets/images/Career-at-BMW.jpg" class="w-100" alt="BMW slides">
        </div>
</section>
    <!-- <section class="nav-header">
        <div class="bmw-header">
        </div>
    </section> -->

    <section>
        <div class="container">
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-lg-6 col-md-12 order-lg-1 order-2">
                    <div class="decription">
                        <h1>
                            ABOUT THE BRAND
                        </h1>
                        <p class="bmw-desc-first-p">
                            ‘JOY’ is at the core of everything we do at BMW, it turns a profession into a vocation. It
                            drives us to keep reinventing mobility and to bring innovative ideas onto the roads. From
                            high performance, BMW M cars to visionary BMW i electric vehicles, we create ‘Sheer Driving
                            Pleasure’ from the perfect combination of dynamic, sporty performance, ground-breaking
                            innovations and breath-taking design.
                        </p>
                        <p class="bmw-desc-second-p">BMW’s passionate promise for the future is born from the unique
                            history of reinvention. At
                            BMW, we don’t just build cars, the brand’s innovation and exceptional design creates
                            emotion, enthusiasm, fascination and thrill.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-lg-2 order-1">
                    <div class="decription-img">
                        <img src="/assets/images/About-the-Brand-1.jpg" class="img-fluid">
                    </div>
                </div>
            </div>
            <hr class="hr-line">
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-lg-6 col-md-12">
                    <div class="decription-img">
                        <img src="/assets/images/Who-Are-We-Looking-For.jpg" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="decription">
                        <h1>
                            WHO WE ARE LOOKING FOR
                        </h1>
                        <p class="bmw-desc-first-p">
                            At BMW Dealer, we are always looking for pioneering new talent to help us continue tackling the
                            defining mobility challenges of our time. For those who share our passion for changing the
                            way the world moves for good, we offer truly exciting and rewarding careers to match the
                            very high ambitions.
                        </p>
                        <p class="bmw-desc-second-p">
                            We value teams with a diverse mix of skills, knowledge and experience and are looking for
                            people who understand our customers and the role that cars play in their lives. If you are
                            passionate, proactive and customer-centric individual with a zeal to excel in automotive
                            sector, you’re the one we need.
                        </p>
                    </div>
                </div>
            </div>
            <hr class="hr-line">
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-md-12">
                    <h1 class="career_type">DEALER ROLES</h1>
                </div>

                <!-- sales manager -->
                <div class="col-lg-6 col-md-12 order-lg-1 order-2">
                    <div class="decription">
                        <h2>
                            SALES MANAGER
                        </h2>
                        <p>
                            You love our vehicles, and you know exactly how to communicate that passion to your team and
                            customers. Leading from the front, you’ll be instrumental in forecasting and developing
                            annual sales potential for your dealership. With your assertive nature and boundless
                            enthusiasm, you will provide leadership to your team and inspire every team member to
                            achieve great results and deliver a great customer experience.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-lg-2 order-1">
                    <div class="decription-img">
                        <img src="/assets/images/Sales-Manager-Role.jpg" class="img-fluid">
                    </div>
                </div>
            </div>

            <!-- marketing manager -->
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-lg-6 col-md-12">
                    <div class="decription-img">
                        <img src="/assets/images/BMW-Marketing-Manager.jpg" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="decription">
                        <h2>
                            Marketing Manager
                        </h2>
                        <p>
                            Your passion for our brand, excellent communication skills and intuitive ability to analyze
                            complex consumer and marketing data will play a pivotal role in building and driving the BMW
                            Brand. You will be responsible for analyzing customer insights and consumer trends to build
                            strategies for enhanced dealership sales penetration through targeted and innovative
                            marketing campaigns and events, that not only build on existing relationships, but generate
                            new ones too.
                        </p>
                    </div>
                </div>
            </div>

            <!-- sales Consultant -->
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-lg-6 col-md-12 order-lg-1 order-2">
                    <div class="decription">
                        <h2>
                            SALES CONSULTANT
                        </h2>
                        <p>
                            As our brand ambassador, you will be responsible for making every customer feel unique and
                            build long lasting relationships. From the first hello to the handing over the keys you’ll
                            bring our products to life, matching every customer to their perfect BMW. Individuals in
                            this role are motivated and always willing to put in extra effort to achieve sales
                            objectives and targets through superior customer consulting, negotiation and closure.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-lg-2 order-1">
                    <div class="decription-img">
                        <img src="/assets/images/Sales-Consultant-BMW.jpg" class="img-fluid">
                    </div>
                </div>
            </div>

            <!-- bmw-genius -->
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-lg-6 col-md-12">
                    <div class="decription-img">
                        <img src="/assets/images/BMW_Genius.jpg" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="decription">
                        <h2>
                            BMW GENIUS
                        </h2>
                        <p>
                            BMW Geniuses learn about BMWs inside and out, as a passionate brand ambassador with a
                            customer-centric personality, you will be the go-to person for all things related to BMW
                            cars, bringing features to life for customers. Wherever our customers are in their journey
                            of sheer driving pleasure, whether a vehicle presentation, a handover or advice on
                            accessories, you will be the one they turn to for support and advice.
                        </p>
                    </div>
                </div>
            </div>
           
            <!-- SERVICE ADVISOR -->
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-lg-6 col-md-12 order-lg-1 order-2">
                    <div class="decription">
                        <h2>
                            SERVICE ADVISOR
                        </h2>
                        <p>
                            You will be responsible for providing premier Service consultation and solutions tailored to
                            the individual needs of each customer to ensure that we don’t just keep our customers happy
                            – but transform them into passionate ambassadors of the Brand. Working in state-of the art
                            environment, you will act as pivotal link between our customers and the workshop to ensure a
                            smooth journey from understanding the repair requirements to booking in work and delivering
                            a completed job.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-lg-2 order-1">
                    <div class="decription-img">
                        <img src="/assets/images/Service-Advisor-BMW-2.jpg" class="img-fluid">
                    </div>
                </div>
            </div>

            <!-- Technician -->
            <div class="row mt-md-5 mb-md-4 mt-4 mb-3">
                <div class="col-lg-6 col-md-12 ">
                    <div class="decription-img">
                        <img src="/assets/images/BMW-Technician.jpg" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 ">
                    <div class="decription">
                        <h2>
                            Technician 
                        </h2>
                        <p>
                            BMW Technicians are dedicated to providing the premium customer experience. They are
                            technology experts who complete repairs and troubleshoot issues to make customers truly
                            happy. BMW Technicians work in state-of-the-art workshops and go through structured
                            trainings and certification programme. Successful BMW technicians are motivated and enjoy
                            the challenge of working on some of the most technically advanced vehicles.
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
        <br>
    </section>
    <a routerLink="/dealer-careers" type="button" class="btn btn-secondary explore-btn">
        EXPLORE CURRENT JOB VACANCIES
    </a>
