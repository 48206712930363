import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { SeoService } from 'src/app/common/services/seo.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [{ provide: CarouselConfig, useValue: { interval: 5000, noPause: true, showIndicators: true, } }]
})
export class LandingComponent implements OnInit {

  pageVars = { kw: "", loc: "" }

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private seoService: SeoService) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      this.seoService.updateMetaTags(seoData['metaTags']);
      this.seoService.updateTitle(seoData['title']);
    });
  }

  ngOnInit(): void {
  }

  searchReqs() {
    var params: any = { src: 'careers' }
    if (this.pageVars.kw && this.pageVars.kw != "") {
      params.search = this.pageVars.kw;
    }
    if (this.pageVars.loc && this.pageVars.loc != "") {
      params.location = this.pageVars.loc;
    }
    this.router.navigate(['/dealer-careers'], { queryParams: params });
  }

}
