import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sign-up-alert',
    templateUrl: './sign-up-alert.component.html',
    styleUrls: ['./sign-up-alert.component.scss']
})
export class SignUpAlertComponent implements OnInit {

    name: any;
    email: any;
    frequency: any;
    brand: any;
    jobCategories: any;
    locations: any;
    isMini: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    DisableMenu() {

        if (this.brand == "mini") {
            this.isMini = true;
        } else {
            this.isMini = false;
        }

    }
    msg() {
        alert("Form Submitted Successfully");
    }
    checkEmail() {

        let email = this.email;
        let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (email == undefined) {
            alert('Please provide a valid email address');
            return false
        }
        if (filter.test(email) == false) {
            alert('Please provide a valid email address');
            email.focus();
            return false;
        }
    }
    loadBadge() {
        let that = this;
        let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                that.msg();
            }
        };
        let input: any = {};

        input.name = this.name;
        input.email = this.email;
        input.frequency = this.frequency;
        input.brand = this.brand;
        input.jobCategories = [this.jobCategories];
        input.locations = [this.locations];

        if (!input.name) {
            alert("Enter Your Name");
            return;
        }
        if (!input.email) {
            alert("Enter Your Email");
            return;
        }
        if(filter.test(input.email) === false)
        {
            alert('Please provide a valid email address');
            return false;
        }

        if (!input.frequency) {
            alert("Enter frequency");
            return;
        }
        if (!input.brand) {
            alert("Enter brand");
            return;
        }

        xhttp.open("POST", "https://bmw.mynexthire.com/employer/careers/bmw/job_alert/opt_in", true);
        xhttp.setRequestHeader("Content-type", "application/json");
        xhttp.send(JSON.stringify(input));

    }

}
