import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './components/landing/landing.component';
import { MiniComponent } from './components/mini/mini.component';
import { BmwComponent } from './components/bmw/bmw.component';
import { DealerCareersComponent } from './components/dealer-careers/dealer-careers.component';
import { DealerLocationsComponent } from './components/dealer-locations/dealer-locations.component';
import { SignUpAlertComponent } from './components/sign-up-alert/sign-up-alert.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationDetailsComponent } from './components/dealer-locations/location-details/location-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SubstringPipe } from './pipes/substring.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConstantsPipe } from './pipes/constants.pipe';
import { TrainingDevelopmentComponent } from './components/training-development/training-development.component';
import { LegalDocumentComponent } from './components/legal-document/legal-document.component';



@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    MiniComponent,
    BmwComponent,
    DealerCareersComponent,
    DealerLocationsComponent,
    SignUpAlertComponent,
    HeaderComponent,
    FooterComponent,
    LocationDetailsComponent,
    SubstringPipe,
    SafePipe,
    ConstantsPipe,
    TrainingDevelopmentComponent,
    LegalDocumentComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTabsModule,

    CarouselModule.forRoot(),
  ],
  providers: [ConstantsPipe],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
