<section class="bmw-slider">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <h1 class="legal-text">
                    Legal Document
                </h1>
            </div>

            <div class="col-md-12">
                <div class="clarification-section">
                    <h5 class="clarification-section-header">CLARIFICATION IN PUBLIC INTEREST</h5>
                    <p>The reputation of BMW, BMW Brand and logo are greatest business assets which has been built on
                        the bedrock of nurturing values and ethics where integrity towards all stakeholders, both
                        internal and external is of utmost importance. We hereby bring to your notice that there are
                        fake job/recruitment offers being made in the name of BMW Group India or under the Company’s
                        name or logo. The candidates who have posted their resumes on certain job portals and websites
                        have been offered employment with us or Authorised Dealers on the condition that they make a
                        payment to specified bank accounts.</p>
                    <p>It is clarified that BMW Group India or its Authorised Dealers does not take payment from
                        candidates for employment purposes or for using this website. </p>

                    <div class="notified-section">
                        <h5 class="notified-header">It is further notified that : </h5>
                        <p><b>a)</b> BMW Group India or its Authorised Dealers have not appointed any agent, agency or
                            individual to make or issue offers of employment or benefits on its behalf.</p>
                        <p><b>b)</b> BMW Group India or its Authorised Dealers does not charge any job seeker any money
                            or deposit or otherwise for giving employment offers or interview.</p>
                    </div>

                    <p>Any job seeker dealing with such an Agency, Employment Portal or Individual will be doing so
                        solely at his or her own risk and BMW Group India or any of its Group entities or its Authorised
                        Dealers do not stand responsible for any damages/loss suffered in the process, directly or
                        indirectly.</p>
                    <p>It will be in your interest that should there be any suspicious activity pertaining to fraudulent
                        enrolment noticed, it be reported to retailhr.india@bmw.in. </p>
                </div>

            </div>


            <div class="col-md-12">
                <h5 class="terms-condition-header">
                    TERMS & CONDITIONS.
                </h5>
                <div class="row">
                    <div class="col-md-6">
                        <div class="Training-list">
                            <ul>
                                <li><i class="fas fa-caret-right"></i><span> Terms</span></li>
                                <li><i class="fas fa-caret-right"></i><span> Lawful Use</span></li>
                                <li><i class="fas fa-caret-right"></i><span> Links</span></li>
                                <li><i class="fas fa-caret-right"></i><span> Copyright</span></li>
                                <li><i class="fas fa-caret-right"></i><span> Protected Rights</span></li>
                                <li><i class="fas fa-caret-right"></i><span> Changes to these Terms and
                                        Conditions</span></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="Training-list">
                            <ul>
                                <li><i class="fas fa-caret-right"></i><span> The Accuracy of your Registration
                                        Information</span></li>
                                <li><i class="fas fa-caret-right"></i><span> Your Username and Password</span></li>
                                <li><i class="fas fa-caret-right"></i><span> Information that you post on the
                                        website</span>
                                </li>
                                <li><i class="fas fa-caret-right"></i><span> Governing Law and Applicable
                                        Legislation</span>
                                </li>
                                <li><i class="fas fa-caret-right"></i><span> Privacy terms</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-md-12">
                <div class="terms-section">
                    <h5>A. Terms</h5>
                    <p>"BMW Dealer" shall mean BMW Authorised Dealers who are appointed by “BMW India Private Limited”.
                    </p>
                    <p>“BMW Group Retailer Network” shall mean the network of Dealers in the BMW Group India Network, a
                        list of which can be found here https:bmwgroupdealercareers.in/#/dealer-locations.</p>
                    <p>Use of this website is governed by these Terms and Conditions and you agree to be bound by them
                        each time you access the website.</p>
                    <p>The material / information on this website is provided purely for your information and you should
                        seek further guidance and make independent inquiries before relying on it. We may make
                        alterations to the website at any time. You will be deemed to accept such alterations when you
                        next use the website following any such alteration.</p>
                    <p>This website is created for supporting the recruitment activities of our retail network . This
                        website is not for offering any job opening to BMW India Private Limited or any other associate
                        company of BMW India Private Limited (collectively referred to as BMW Group India). The position
                        available in the website are available at “BMW Group Retailer Network”</p>
                    <p>Any employment placement will be subject to Dealer’s current standard employment terms and
                        conditions, which may vary upon Dealer wise or its location. You have sole discretion to accept
                        or reject the Job Offer, if any, if Your credentials found suitable and acceptable. No liability
                        or claim or communication will be entertained in this regard.</p>
                    <p>The information on this website is updated from time to time. Whilst we have made every effort to
                        ensure the accuracy and completeness of information on this website, we make no representations
                        or warranties whatsoever, express or implied, as to the quality, accuracy or completeness of
                        such information. The information and details are provided solely on the basis of information /
                        details shared by BMW Dealer.</p>
                    <p>We may without notice modify, suspend or discontinue the website or any part of it at any time
                        without any liability to you or any third party.</p>
                    <p>To the full extent permitted by law, we accepts no liability in contract, tort or otherwise
                        (including liability for negligence), for loss or damage of any kind including without
                        limitation, direct or indirect loss or damage, loss of business, revenue or profits, corruption
                        or destruction of data, or any other consequential loss or damage arising out of your use or
                        inability to use the website (or other site linked to the website) or in connection with any
                        computer virus or system failure and We excludes any such liability even if Dealer has expressly
                        advised of the possibility of such damage or loss</p>
                    <p>You will indemnify BMW Group India or respective Dealer against all costs, losses, expenses or
                        other liabilities incurred by BMW Group India or respective Dealer arising from the use of the
                        website by you.</p>
                </div>

                <div class="terms-section">
                    <h5>B. LAWFUL USE</h5>
                    <p>You will use the website for lawful purposes only</p>
                </div>

                <div class="terms-section">
                    <h5>C. LINKS</h5>
                    <p>You should note that this site may provide links to web sites maintained by others. We accept no
                        responsibility or liability for the accuracy or legality of any content contained in such
                        websites. The fact that you may use a other links to access other websites is not an endorsement
                        by us or any content contained in those websites. Neither you nor any third party may link
                        another site to this website without our prior written consent.</p>
                </div>

                <div class="terms-section">
                    <h5>D. COPYRIGHT</h5>
                    <p>Unless otherwise stated, the copyright and similar rights in this web site and in all the
                        material contained on this website belong to us. You are only permitted to copy or print
                        extracts of the material for your own personal use. You may not use any of this material for
                        commercial or public purposes.</p>
                    <p>Without our written permission, you may not (whether directly or indirectly including through the
                        use of any programme) create a database in an electronic or other form by downloading and
                        storing all or any part of the pages from this website. Without the permission of us, no part of
                        this website may be reproduced, transmitted to or stored on any other website, disseminated in
                        any electronic or non-electronic form, or included in any public or private electronic retrieval
                        system. Any request for such permission may be sent to BMW Group India by e-mail to
                        retailhr.india@bmw.in</p>
                </div>

                <div class="terms-section">
                    <h5>E. PROTECTED RIGHTS</h5>
                    <p>BMW is the registered trademark of BMW AG, Germany. Misuse or reproduction of any BMW trademark,
                        service mark, logo or any other intellectual property right contained on this website is
                        strictly prohibited. You agree not to use the website in any way which infringes, or is likely
                        to infringe, any trademark, service mark, logo, copyright or any other intellectual property
                        rights of BMW Group.</p>
                </div>

                <div class="terms-section">
                    <h5>F. CHANGES TO THESE TERMS AND CONDITIONS</h5>
                    <p>We may add to or change these Terms and Conditions from time to time. You are deemed to have
                        accepted changed or additional Terms and Conditions when you access the website following any
                        such change or addition.</p>
                </div>

                <div class="terms-section">
                    <h5>G. THE ACCURACY OF YOUR REGISTRATION INFORMATION</h5>
                    <p>You are responsible for ensuring that any information you provide to this website, including your
                        CV, is accurate, complete and your own. If Dealer has any reason to believe that any information
                        you have supplied is false, inaccurate or not your own, we may remove your ability to log-in to
                        the site and may prohibit you from using this site. We are entitled, forthwith and without
                        notice, to remove from the website any such information found to be false, inaccurate,
                        incomplete or not your own.</p>
                </div>

                <div class="terms-section">
                    <h5>H. YOUR USERNAME AND PASSWORD</h5>
                    <p>You are responsible for all use of this site made using your user name and password, whether or
                        not such use is made by you or by someone else using your user name and password. You are
                        responsible for protecting and securing your user name and password from unauthorised use. Your
                        user name and password must not be disclosed to another person. If you believe there has been a
                        breach of security of your user name or password, such as theft or your username or password
                        becoming known to someone else or unauthorised use, you must notify us immediately by e-mail to
                        retailhr.india@bmw.in</p>
                </div>

                <div class="terms-section">
                    <h5>I. INFORMATION THAT YOU POST ON THE WEBSITE</h5>
                    <p>We reserves the right, at your cost, at any time to remove any material from the site which it
                        believes to be salacious, defamatory or offensive or which we believes may be in breach of a
                        third party's rights, such as a third party's intellectual property or confidentiality rights.
                        You agree to indemnify BMW Group India on a full and continuing basis against any loss or damage
                        suffered or costs (including legal costs) incurred by us or by respective Dealer in defending
                        any action brought against BMW Group India or by respective Dealer as a result of any
                        information you have posted on the website.</p>
                </div>

                <div class="terms-section">
                    <h5>J. PRIVACY TERMS </h5>
                    <div class="privacy-terms-section">
                        <h5>1. Introduction</h5>
                        <p>When you complete this form, please note that BMW Group India collects your personal
                            information from you, directly to establish and maintain contact with you for the purposes
                            of this transaction and related purposes. If you do not provide or BMW cannot otherwise
                            collect all the information we request or need, BMW or its Dealer may not be able to proceed
                            with the contemplated transaction.</p>
                        <p>BMW Group India respects your privacy and will treat your personal information as
                            confidential. BMW Group India and its Dealers will use and share your personal information
                            as described in this Privacy Policy and you hereby agree and give your consent for usage of
                            the same.</p>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>2. Authorised Dealers or Retail Sales or Service Centres</h5>
                        <p>Dealers are independent businesses and not part of the BMW Group India. They operate using
                            the BMW brands under valid permission to sell and/or service vehicles. They provide services
                            to customers and support for technical issues; They undertake permitted business activity.
                        </p>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>3. Information We Collect</h5>
                        <p>When you apply for a job at this site, we will ask you to provide us with personal
                            information about yourself so we can evaluate your application. If this information is not
                            provided, our ability to consider you as a candidate may be limited. You may also provide us
                            with your personal information that we have not specifically requested (for example, your CV
                            may contain information about your hobbies and social preferences). All information is
                            provided on a voluntarily basis and you determine the extent of information that you
                            provide.</p>
                        <ul class="privacy-terms-list">
                            <li>contact information (such as name, postal address, email address and telephone number).
                            </li>
                            <li>username and password when you register on our Sites.</li>
                            <li>information you provide about friends or other people you would like us to contact.</li>
                            <li>other information you may provide to us, such as in surveys or
                                through the "Contact Us" feature on our Sites.
                            </li>
                        </ul>
                        <p>In addition, if you are an associate or job candidate, you apply for a position or create an
                            account to apply for a position, we may collect the following types of personal data (as
                            permitted under local law) :</p>
                        <ul class="privacy-terms-list">
                            <li>employment and education history.</li>
                            <li>language proficiencies and other work-related skills.</li>
                            <li>date of birth.</li>
                            <li>gender.</li>
                            <li>benefits information.</li>
                            <li>information contained in your resume or C.V., information you provide regarding your
                                career interests, and other information about your qualifications for employment and
                                where required by law and explicit consent has been provided by you.</li>
                        </ul>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>4. How We Use the Information We Collect</h5>
                        <p>BMW will collect and use the data gathered for the following purposes : </p>
                        <ul class="privacy-terms-list">
                            <li>providing workforce solutions and connecting people to work.</li>
                            <li>creating and managing online accounts.</li>
                            <li>Recruitment, selection, evaluation and appointment of job candidates (temporary or
                                permanent) for the job you have applied for and for subsequent job opportunities.</li>
                            <li>Application analysis such as verification of your employment reference(s) that you have
                                provided, background checks and related assessments.</li>
                            <li>Communicate with you and to inform you of current status of your application and future
                                opportunities (unless you have told us that you do not want us to keep your information
                                for such purposes).</li>
                            <li>where permitted under law, consistent with the Cookie and only if you have given your
                                consent, to send promotional materials, alerts regarding available positions and other
                                communications.</li>
                        </ul>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>5. How We Process and Protect Personal Information</h5>
                        <p>We process the personal data we collect for the purposes defined in this notice and for a
                            period only as long as is necessary for the purposes we collected it. Different laws may
                            also obligations may also require us to keep different data for different periods of time.
                        </p>
                        <p>We maintain administrative, technical and physical safeguards designed to protect the
                            personal data you provide against accidental, unlawful or unauthorized destruction, loss,
                            alteration, access, disclosure or use. In order to ensure the appropriate security and
                            confidentiality of the personal data, we apply to the following security measures : </p>
                        <ul class="privacy-terms-list">
                            <li>Encryption of data in transit.</li>
                            <li>Strong user authentication controls.</li>
                            <li>Hardened network infrastructure.</li>
                            <li>Network monitoring solutions.</li>
                        </ul>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>6. How long we Store The Data We Collect</h5>
                        <p>We will keep your personal data for the time period necessary to achieve the purposes
                            described in this Privacy Policy, taking into account applicable statute of limitation
                            periods and records retention requirements under applicable law. Subject to applicable law,
                            we will retain your personal data as required by the company to meet our business and
                            compliance obligations, for example, to comply with our tax and accounting obligations.</p>
                        <p>We store in our systems the personal data we collect in a way that allows the identification
                            of the data subjects for no longer than it is necessary in light of the purposes for which
                            the data was collected, or for which that data is further processed.</p>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>7. We determine this specific period of time by taking into account :</h5>

                        <ul class="privacy-terms-list">
                            <li>The necessity to keep stored the personal data collected in order to offer services
                                established with the user.</li>
                            <li>The existence of specific legal obligations that make the processing and related storage
                                necessary for specific period of times.</li>
                        </ul>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>8. Information We Share :</h5>
                        <p>We do not disclose personal data that we collect about you, except as described in this
                            privacy policy or in separate notices provided in connection with particular activities. We
                            may share personal data with vendors who perform services on our behalf based on our
                            instructions. We do not authorize any third party to use or disclose the information except
                            as necessary to perform services on our behalf or comply with legal requirements. We also
                            may share your personal data (i) BMW Dealer or with our subsidiaries and affiliates; (ii) if
                            you are a job candidate, with concerned authorities who may have job opportunities available
                            or interest in placing our job candidates.
                        <p>
                        <p>In addition, we may disclose personal data about you (i) if we are required to do so by law
                            or legal process; (ii) to law enforcement authorities or other government officials based on
                            a lawful disclosure request; and (iii) when we believe disclosure is necessary or
                            appropriate to prevent physical harm or financial loss, or in connection with an
                            investigation of suspected or actual fraudulent or illegal activity. We also reserve the
                            right to transfer personal data we have about you in the event we sell or transfer all or a
                            portion of our business or assets (including in the event of a reorganization, dissolution
                            or liquidation).</p>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>9. Compliance with legal requests for your information – to comply with our legal
                            obligations to law enforcement, regulators and the court service.</h5>
                        <p>We may be legally required to provide your information to law enforcement agencies,
                            regulators, courts and third party litigants in connection with civil or criminal
                            proceedings or investigations anywhere in the world.
                        <p>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>10. Data Transfers</h5>
                        <p>We also may transfer the personal data we collect about you to countries outside of the
                            country in which the information originally was collected or to Dealers. Those countries may
                            not have the same data protection laws as the country in which you initially provided the
                            personal data. When we transfer your information to other countries, we will protect that
                            data as described in this privacy policy and such transfers will be in compliance with
                            applicable law.
                        <p>
                    </div>

                    <div class="privacy-terms-section">
                        <h5>11. Security practices and procedures</h5>
                        <p>BMW Group India has in place a security system, to ensure that the personal information is
                            protected from unauthorised access, use, disclosure or alteration by anyone including the
                            employees of BMW Group India.
                        <p>
                        <p>
                            BMW Group India undertakes a number of security measures to maintain the safety of the
                            Provider’s personal information, which includes the use of: physical secure data centres and
                            premises; internal security policies and procedures; defined internal segregation of duties;
                            and electronic access controls such as passwords and encryption technology.
                        </p>
                    </div>

                </div>

                <div class="terms-section">
                    <h5>K. GOVERNING LAW AND APPLICABLE LEGISLATION</h5>
                    <p>These Terms and Conditions are governed by Indian law and you agree that the courts at New Delhi
                        shall have exclusive jurisdiction to determine any matter or dispute arising out of or in
                        connection with use of this website and these Terms and Conditions.</p>
                    <p>Use of this website may not be allowed in countries outside India where such use may be contrary
                        to local law or regulation. If you access information on this website it is your sole
                        responsibility to ensure compliance with any applicable laws or regulations in any other
                        country. Any use of this website outside India is your responsibility and we accept no liability
                        whatsoever in connection with such use.</p>
                    <p>BMW Group India or its respective Dealer has the right at any time to terminate or suspend access
                        to, or use of, the website where BMW Group India or its respective Dealer reasonably believes
                        you have infringed the Terms and Conditions.</p>
                </div>
                <br>
            </div>
        </div>
    </div>
</section>