import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'constants'
})
export class ConstantsPipe implements PipeTransform {

  transform(input:any, param?:any): unknown {
    switch (input) {
      case 'instance':
          return 'https://bmw.mynexthire.com';  
      default:
        break;
    }
    return null;
  }

}
