<section class="page-top-padding">
    <div class="container">
        <h1 class="location-title">OUR DEALERS</h1>
        <div *ngFor="let region of dealerRegions | keyvalue">
            <div class="location-directions">
                <h1>{{region.key | substring:3}}</h1>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6" *ngFor="let location of region.value">
                    <div class="location-card">
                        <h4>{{location.showroom}}</h4>
                        <p>{{location.subLocation}}  <b>{{location.location?('- ' + location.location):''}}</b></p>
                        <p>{{location.subLocation2}} <b>{{location.location2?('- ' + location.location2):''}}</b></p>
                        <button type="button" class="btn location-btn"
                            (click)="openDialog(location.findUs)">Find us</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>