import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { SeoService } from 'src/app/common/services/seo.service';

@Component({
  selector: 'app-training-development',
  templateUrl: './training-development.component.html',
  styleUrls: ['./training-development.component.scss'],
  providers : [{ provide: CarouselConfig, useValue: { interval: 5000, noPause: true, showIndicators: true,  } }]
})
export class TrainingDevelopmentComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private seoService: SeoService) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      this.seoService.updateMetaTags(seoData['metaTags']);
      this.seoService.updateTitle(seoData['title']);
    });
  }
  
  ngOnInit(): void {
  }

}
