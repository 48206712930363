<section>
    <nav class="navbar navbar-expand-lg navbar-light bmw-navbar">
        <div class="brands-link">
            <a class="navbar-brand" routerLink="/landing">
                <img class="bmw-gr-logo img-fluid" src="/assets/images/grp-logo.png">
            </a>
            <a class="navbar-brand" routerLink="/bmw">
                <img class="img-fluid" src="/assets/images/bmw.png">
            </a>
            <a class="navbar-brand" routerLink="/mini">
                <img class="img-fluid" src="/assets/images/Mini-2.png">
            </a>
        </div>

        <button class="navbar-toggler" type="button" (click)="toggleNavbar = !toggleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="{{toggleNavbar?'collapse':''}} navbar-collapse">
            <ul class="navbar-nav ml-auto" (click)="toggleNavbar = !toggleNavbar">
                <li class="nav-item">
                    <a class="nav-link underline-reveal" routerLink="/landing" routerLinkActive="active" ><span class="nav-home-padding">Home</span></a>
                </li>
                <li class="nav-item bmw-nav-dropdown dropdown" routerLinkActive="active" (click)="toggleNavbar = !toggleNavbar">
                    <a class="nav-link dropdown-toggle underline-reveal">
                        Why Work For Us
                    </a>
                    <div class="dropdown-menu nav-dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item" routerLink="/bmw" (click)="toggleNavbar = !toggleNavbar">BMW Dealer</a>
                      <a class="dropdown-item" routerLink="/mini" (click)="toggleNavbar = !toggleNavbar">MINI Dealer</a>
                    </div>
                  </li>
                <li class="nav-item">
                    <a class="nav-link underline-reveal" routerLink="/training-development" routerLinkActive="active">Training & Development</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link underline-reveal" routerLink="/dealer-careers" routerLinkActive="active">Current Openings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link underline-reveal" routerLink="/dealer-locations" routerLinkActive="active">Dealer Location</a>
                </li>
                
                <li class="nav-item mobile-signUp">
                    <a class="nav-link underline-reveal" routerLink="/sign-up-alert" routerLinkActive="active">Sign-Up For Alerts</a>
                </li>
            </ul>
            <a class="desktop-signUp" routerLink="/sign-up-alert">Sign-Up For Alerts</a>
        </div>
    </nav>
</section>